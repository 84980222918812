import React from 'react'
import SyncLoader from 'react-spinners/SyncLoader'
import { Line, Pie } from 'react-chartjs-2'

export const Avaya1Report = ({
  loading,
  report,
  getReport,
  showReport,
  setShowReport,
  showTable,
  setShowTable,
}) => {
  const labels = [
    '0',
    '30s',
    '45s',
    '3m',
    '10m',
    '15m',
    '20m',
    '30m',
    '45m',
    '1h',
    '2h',
  ]

  let data = []

  if (report) {
    data = [
      report.total,
      report.tiempo_30_s,
      report.tiempo_45_s,
      report.tiempo_3_m,
      report.tiempo_10_m,
      report.tiempo_15_m,
      report.tiempo_20_m,
      report.tiempo_30_m,
      report.tiempo_45_m,
      report.tiempo_1_h,
      report.tiempo_2_h,
    ]
  }

  return (
    <div className="report">
      <div className="report__header">
        <h2>
          Avaya1
          <div
            className={`arrow${showReport ? ' open' : ''}`}
            onClick={() => {
              if (!report) {
                getReport()
              }
              setShowReport(!showReport)
            }}
          />
        </h2>

        {showReport && (
          <div className="buttons">
            {!report && <span>No hay registros.</span>}

            <button onClick={getReport}>Actualizar</button>

            <button onClick={() => setShowTable(!showTable)}>
              Ver {showTable ? 'gráfica' : 'tabla'}
            </button>
          </div>
        )}

        {showReport && (
          <div className="loader">
            <SyncLoader color="#000" loading={loading} />
          </div>
        )}
      </div>

      {!showTable && showReport && report && (
        <div
          className="report__graph"
          style={{
            position: 'relative',
          }}
        >
          <Line
            width={100}
            height={30}
            responsive
            data={{
              datasets: [
                {
                  data,
                  fill: 'origin',
                  backgroundColor: 'rgb(140,173,214)',
                },
              ],
              labels,
            }}
            options={{
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      display: true,
                      color: 'transparent',
                    },
                  },
                ],
                yAxes: [
                  {
                    gridLines: {
                      display: true,
                      color: 'transparent',
                    },
                  },
                ],
              },
            }}
          />

          <div
            style={{
              width: '300px',
              height: '300px',
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <Pie
              width={100}
              height={100}
              data={{
                datasets: [
                  {
                    data: [report.total - report.espera, report.espera],
                    backgroundColor: ['rgb(19,121,148)', 'rgb(200,42,55)'],
                  },
                ],
                labels: [
                  'Conectado ' +
                    Math.round(
                      ((report.total - report.espera) / report.total) * 100
                    ) +
                    '%',

                  'En espera ' +
                    Math.round((report.espera / report.total) * 100) +
                    '%',
                ],
              }}
            />
            <div className="text-center">TOTAL: {report.total}</div>
          </div>
        </div>
      )}

      {showTable && showReport && (
        <div className="report__table">
          <table>
            <thead>
              <tr>
                <th>Tiempo</th>
                <th>Cantidad de llamadas</th>
              </tr>
            </thead>
            <tbody>
              {report &&
                labels.map((label, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{label}</td>
                      <td>{data[idx]}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
