import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select'
import SyncLoader from 'react-spinners/SyncLoader'
import { toast } from 'react-toastify'
import moment from 'moment'

import './reports.scss'

import Layout from '../components/Layout'
import { getDiagnosticCatalogs } from '../services/api/get-diagnostics'
import { getReports } from '../services/api/reports'
import { getAvaya } from '../services/api/avaya'
import { BarsReport } from '../components/BarsReport'
import { BarsReportAge } from '../components/BarsReportAge'
import { ForcesPie } from '../components/PieReport'
import { Avaya1Report, Avaya2Report } from '../components/AvayaReport'
import Radio from '../components/Radio'
import { getForces } from '../services/api/forces'
import { getOrganigrams } from '../services/api/organigrams'
import { Context } from '../Context'
import { hasRole, isBrowser } from '../components/Auth'

export default function ReportPage () {
  const { isAuth, loggedUser } = useContext(Context)

  if (isBrowser() && !isAuth) {
    window.location.href = '/'
    return (
      <Layout withoutHeader className='page-reports'>
        Cargando...
      </Layout>
    )
  }

  const [values, setValues] = useState(() => {
    return {
      date1: moment()
        .subtract(3, 'months')
        .format('YYYY-MM-DD'),
      date2: moment().format('YYYY-MM-DD'),
      diagnosticCatalogId: 2
    }
  })

  const [forceId, setForceId] = useState(loggedUser.force_id)

  // indica si se tienen en cuenta remisiones, sesiones ó llamadas
  const [source, setSource] = useState('sessions')

  // datasets
  const [diagnosticCatalogs, setDiagnosticCatalogs] = useState([])
  const [forces, setForces] = useState([])
  const [organigrams, setOrganigrams] = useState([])

  // diagnostics
  const [diagnosticsReport, setDiagnosticsReport] = useState({
    data: [],
    total: 0
  })
  const [loadingDiagnosticsReport, setLoadingDiagnosticsReport] = useState(
    false
  )
  const [showDiagnosticsTable, setShowDiagnosticsTable] = useState(false)
  const [showDiagnosticsReport, setShowDiagnosticsReport] = useState(false)

  // organigrams
  const [organigramReports, setOrganigramReports] = useState([
    { data: [], total: 0 }
  ])
  const [showForcesReport, setShowForcesReport] = useState(false)
  const [loadingOrganigramsReport, setLoadingOrganigramsReport] = useState(
    false
  )

  // problematics
  const [problematicsReport, setProblematicsReport] = useState({
    data: [],
    total: 0
  })
  const [loadingProblematicsReport, setLoadingProblematicsReport] = useState(
    false
  )
  const [showProblematicsTable, setShowProblematicsTable] = useState(false)
  const [showProblematicsReport, setShowProblematicsReport] = useState(false)

  // psycologists
  const [psycologistsReport, setPsycologistsReport] = useState({
    data: [],
    total: 0
  })
  const [loadingPsycologistsReport, setLoadingPsycologistsReport] = useState(
    false
  )
  const [showPsycologistsTable, setShowPsycologistsTable] = useState(false)
  const [showPsycologistsReport, setShowPsycologistsReport] = useState(false)

  // age
  const [ageReport, setAgeReport] = useState({
    data: [],
    age_ranges: [],
    ranks: []
  })
  const [loadingAgeReport, setLoadingAgeReport] = useState(false)
  const [showAgeTable, setShowAgeTable] = useState(false)
  const [showAgeReport, setShowAgeReport] = useState(false)

  // avaya1
  const [avaya1, setAvaya1] = useState()
  const [loadingAvaya1, setLoadingAvaya1] = useState(false)
  const [showAvaya1Report, setShowAvaya1Report] = useState(false)
  const [showAvaya1Table, setShowAvaya1Table] = useState(false)

  // avaya2
  const [avaya2, setAvaya2] = useState()
  const [loadingAvaya2, setLoadingAvaya2] = useState(false)
  const [showAvaya2Report, setShowAvaya2Report] = useState(false)

  // obtiene del backend los registros de las tablas parameters
  useEffect(() => {
    // obtiene del backend los catálogos de diagnóstico
    getDiagnosticCatalogs()
      .then(res => {
        const catalogs = res.data.map(i => {
          return {
            value: i.id,
            label: i.name
          }
        })
        setDiagnosticCatalogs(catalogs)
      })
      .catch(err => console.error(err.response))

    // obtiene del backend las fuerzas
    getForces().then(res => {
      if (res.status === 200) {
        let data = res.data.map(i => {
          return {
            value: i.id,
            label: i.name
          }
        })

        data = [{ value: 0, label: 'Todas' }, ...data]

        setForces(data)
      }
    })

    // obtiene del backend los organigrams con parent 0
    getOrganigrams({ action: 'forces' }).then(res => {
      if (res.status === 200) {
        setOrganigrams(res.data)
      }
    })
  }, [])

  // gestiona el cambio de los campos
  function onChange (e) {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  // get diagnostics report
  function getDiagnosticsReport () {
    if (!values.date1) {
      toast.error('La fecha inicio es requerida')
      return
    } else if (!values.date2) {
      toast.error('La fecha final es requerida')
      return
    } else if (!values.diagnosticCatalogId) {
      toast.error('El catálogo de diagnósticos es requerido')
      return
    }

    const params = {
      action: 'diagnostics',
      source,
      diagnostic_catalog_id: values.diagnosticCatalogId,
      date1: values.date1,
      date2: values.date2
    }

    if (forceId) {
      params.force_id = forceId
    }

    setLoadingDiagnosticsReport(true)
    getReports(params)
      .then(res => {
        if (res.status === 200) {
          const data = res.data.data.sort((a, b) => {
            if (a.total > b.total) {
              return -1
            } else if (a.total < b.total) {
              return 1
            }
            return 0
          })

          setDiagnosticsReport({
            ...res.data,
            data
          })
        }
      })
      .catch(() => {
        toast.error('No fue posible obtener el reporte de diagnósticos')
      })
      .finally(() => setLoadingDiagnosticsReport(false))
  }

  // get organigram report
  function getOrganigramReport ({ organigramId, reset }) {
    if (!values.date1) {
      toast.error('La fecha inicio es requerida')
      return
    } else if (!values.date2) {
      toast.error('La fecha final es requerida')
      return
    }

    const params = {
      action: 'organigrams',
      source,
      date1: values.date1,
      date2: values.date2,
      organigram_id: organigramId
    }

    if (forceId && !organigramId) {
      const organigram = organigrams.find(i => i.force_id === forceId)
      if (organigram) {
        params.organigram_id = organigram.id
      }
    }

    if (source === 'history') {
      delete params.organigram_id
      params.action = 'forces'
      params.force_id = forceId
    }

    setLoadingOrganigramsReport(true)
    getReports(params)
      .then(res => {
        if (res.status === 200) {
          const data = res.data.data.sort((a, b) => {
            if (a.total > b.total) {
              return -1
            } else if (a.total < b.total) {
              return 1
            }
            return 0
          })

          const report = {
            ...res.data,
            data
          }

          if (reset || organigramReports[0].data.length === 0) {
            setOrganigramReports([report])
          } else {
            setOrganigramReports([...organigramReports, report])
          }
        }
      })
      .catch(err => {
        console.error(err)
        toast.error('No fue posible obtener el reporte de organigramas')
      })
      .finally(() => setLoadingOrganigramsReport(false))
  }

  // get problematics report
  function getProblematicsReport () {
    if (!values.date1) {
      toast.error('La fecha inicio es requerida')
      return
    } else if (!values.date2) {
      toast.error('La fecha final es requerida')
      return
    }

    const params = {
      action: 'problematics',
      source,
      date1: values.date1,
      date2: values.date2
    }

    if (forceId) {
      params.force_id = forceId
    }

    setLoadingProblematicsReport(true)
    getReports(params)
      .then(res => {
        if (res.status === 200) {
          if (res.data.data.length) {
            const data = res.data.data.sort((a, b) => {
              if (a.total > b.total) {
                return -1
              } else if (a.total < b.total) {
                return 1
              }
              return 0
            })

            setProblematicsReport({
              ...res.data,
              data
            })
          }
        }
      })
      .catch(err => {
        console.error(err)
        toast.error('No fue posible obtener el reporte de problemáticas')
      })
      .finally(() => setLoadingProblematicsReport(false))
  }

  // get psycologist report
  function getPsycologistsReport () {
    if (!values.date1) {
      toast.error('La fecha inicio es requerida')
      return
    } else if (!values.date2) {
      toast.error('La fecha final es requerida')
      return
    }

    const params = {
      action: 'psycologists',
      source,
      date1: values.date1,
      date2: values.date2
    }

    if (forceId) {
      params.force_id = forceId
    }

    setLoadingPsycologistsReport(true)
    getReports(params)
      .then(res => {
        if (res.status === 200) {
          const data = res.data.data.sort((a, b) => {
            if (a.total > b.total) {
              return -1
            } else if (a.total < b.total) {
              return 1
            }
            return 0
          })

          setPsycologistsReport({
            ...res.data,
            data
          })
        }
      })
      .catch(err => {
        console.error(err)
        toast.error('No fue posible obtener el reporte de psicólogas')
      })
      .finally(() => setLoadingPsycologistsReport(false))
  }

  // get years (age) report
  function getAgeReport () {
    if (!values.date1) {
      toast.error('La fecha inicio es requerida')
      return
    } else if (!values.date2) {
      toast.error('La fecha final es requerida')
      return
    }

    const params = {
      action: 'years',
      source,
      date1: values.date1,
      date2: values.date2
    }

    if (forceId) {
      params.force_id = forceId
    }

    setLoadingAgeReport(true)
    getReports(params)
      .then(res => {
        if (res.status === 200) {
          setAgeReport(res.data)
        }
      })
      .catch(() => {
        toast.error('No fue posible obtener el reporte de edades')
      })
      .finally(() => setLoadingAgeReport(false))
  }

  // get avaya1 report
  function getAvaya1Report () {
    setLoadingAvaya1(true)
    getAvaya({
      action: 'avaya1',
      date1: `${values.date1} 00:00:00`,
      date2: `${values.date2} 23:59:59`
    })
      .then(res => {
        if (res.status === 200) {
          setAvaya1(res.data)
        }
      })
      .catch(() => {
        toast.error('No fue posible obtener el reporte de avaya1')
      })
      .finally(() => setLoadingAvaya1(false))
  }

  // get avaya2 report
  function getAvaya2Report () {
    setLoadingAvaya2(true)
    getAvaya({
      action: 'avaya2',
      date1: `${values.date1} 00:00:00`,
      date2: `${values.date2} 23:59:59`
    })
      .then(res => {
        if (res.status === 200) {
          setAvaya2(res.data)
        }
      })
      .catch(() => {
        toast.error('No fue posible obtener el reporte de avaya2')
      })
      .finally(() => setLoadingAvaya2(false))
  }

  // get all reports
  function getAllReports () {
    getDiagnosticsReport()

    getOrganigramReport({
      organigramId: 0,
      reset: true
    })

    getProblematicsReport()
    getPsycologistsReport()
    getAgeReport()
  }

  return (
    <Layout className='page-reports'>
      <h1>Reportes</h1>

      <div className='filters'>
        <div className='filters__row'>
          <label>
            <div>Fecha inicio</div>
            <input
              type='date'
              name='date1'
              value={values.date1}
              onChange={onChange}
              className='textfield'
            />
          </label>

          <label>
            <div>Fecha fin</div>
            <input
              type='date'
              name='date2'
              value={values.date2}
              onChange={onChange}
              className='textfield'
            />
          </label>

          <label className='group-select'>
            <div>Diagnóstico</div>
            <div style={{ display: 'flex' }}>
              <Select
                options={diagnosticCatalogs}
                onChange={option => {
                  setValues({
                    ...values,
                    diagnosticCatalogId: option.value
                  })
                }}
                value={diagnosticCatalogs.find(i => {
                  return i.value === values.diagnosticCatalogId
                })}
                placeholder='Catálogo'
                className='select'
                classNamePrefix='select'
              />
            </div>
          </label>

          <label className='group-select'>
            <div>Fuerza</div>
            <div style={{ display: 'flex' }}>
              <Select
                options={forces}
                onChange={option => setForceId(option.value)}
                value={forces.find(i => i.value === forceId)}
                placeholder='Fuerza'
                className='select'
                classNamePrefix='select'
                isDisabled={hasRole(3)}
              />
            </div>
          </label>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
          }}
        >
          <div>
            <p>
              Apreciado usuario, tenga en cuenta lo siguiente respecto de los
              reportes:
            </p>
            {source === 'sessions' && (
              <p>
                El filtro <strong>SESIONES</strong> debe utilizarse para
                reportes posteriores al 1 de Octubre de 2019. (fecha de entrada
                a producción de Lanza v5.)
              </p>
            )}

            {source === 'patients' && (
              <p>
                En el filtro <strong>PACIENTES</strong> se debe tener en
                consideración que un paciente puede tener varias sesiones por
                ende varias problemáticas, varios diagnósticos y diferentes
                psicólogas en un mismo período consultado.
              </p>
            )}

            {source === 'history' && (
              <>
                <p>
                  El filtro <strong>HISTÓRICO</strong> contiene los reportes
                  presentados a la DGSM entre 2012 y Diciembre de 2019. De los
                  cuales es preciso tener en cuenta:
                </p>
                <ol>
                  <li>
                    El año 2012 contiene: Problemática y Avaya únicamente.
                  </li>
                  <li>
                    Entre 2013 - 2016 los reportes no contienen diagnóstico.
                  </li>
                </ol>
              </>
            )}

            {source === 'remissions' && (
              <p>
                El filtro <strong>REMISIONES</strong> contiene los consultantes
                remitidos para atención directa de la DGSM. La información de
                remisiones inicia en 2014.
              </p>
            )}
          </div>

          <div className='radios-group'>
            <Radio
              checked={source === 'sessions'}
              onChange={() => setSource('sessions')}
              className='dark'
              containerclassname='size-18'
            />
            <div>Sesiones</div>

            <Radio
              checked={source === 'patients'}
              onChange={() => setSource('patients')}
              className='dark'
              containerclassname='size-18'
            />
            <div>Pacientes</div>

            <Radio
              checked={source === 'remissions'}
              onChange={() => setSource('remissions')}
              className='dark'
              containerclassname='size-18'
            />
            <div>Remisiones</div>

            <Radio
              checked={source === 'history'}
              onChange={() => setSource('history')}
              className='dark'
              containerclassname='size-18'
            />
            <div>Histórico</div>
          </div>
        </div>
      </div>

      <div className='reports-container'>
        {/* diagnostics */}
        <BarsReport
          title='Diagnóstico'
          loading={loadingDiagnosticsReport}
          report={diagnosticsReport}
          reportLabel='diagnostic'
          getReport={getAllReports}
          showReport={showDiagnosticsReport}
          setShowReport={setShowDiagnosticsReport}
          showTable={showDiagnosticsTable}
          setShowTable={setShowDiagnosticsTable}
        />

        {/* forces */}
        <div className='report'>
          <div className='report__header'>
            <h2>
              Fuerza
              <div
                className={`arrow${showForcesReport ? ' open' : ''}`}
                onClick={() => {
                  if (organigramReports[0].data.length === 0) {
                    getOrganigramReport({ organigramId: 0 })
                  }
                  setShowForcesReport(!showForcesReport)
                }}
              />
            </h2>

            {showForcesReport && (
              <div className='buttons'>
                {!organigramReports[0].data.length && (
                  <span>No hay registros.</span>
                )}
                <button
                  onClick={() => {
                    getAllReports()
                  }}
                >
                  Actualizar
                </button>
              </div>
            )}
          </div>

          {showForcesReport && (
            <div className='loader'>
              <SyncLoader color='#000' loading={loadingOrganigramsReport} />
            </div>
          )}

          {showForcesReport && (
            <div className='report__graph'>
              {organigramReports[0].data.length > 0 && (
                <ForcesPie
                  report={organigramReports[0]}
                  labelField='organigram_name'
                  addReport={getOrganigramReport}
                />
              )}

              {/* organigram sub-reports */}
              {organigramReports.map((report, idx) => {
                if (idx === 0 || !report.total) {
                  return null
                }

                return (
                  <>
                    <hr />
                    <h3 className='text-center'>{report.name}</h3>
                    <div key={idx} className='report'>
                      <div className='report__header'>
                        <ForcesPie
                          report={report}
                          labelField='organigram_name'
                          addReport={getOrganigramReport}
                        />
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          )}
        </div>

        {/* problematics */}
        <BarsReport
          title='Problemática'
          loading={loadingProblematicsReport}
          report={problematicsReport}
          reportLabel='problematic'
          getReport={getAllReports}
          showReport={showProblematicsReport}
          setShowReport={setShowProblematicsReport}
          showTable={showProblematicsTable}
          setShowTable={setShowProblematicsTable}
        />

        {/* psycologists */}
        <BarsReport
          title='Psicóloga'
          loading={loadingPsycologistsReport}
          report={psycologistsReport}
          reportLabel='psycologist'
          getReport={getAllReports}
          showReport={showPsycologistsReport}
          setShowReport={setShowPsycologistsReport}
          showTable={showPsycologistsTable}
          setShowTable={setShowPsycologistsTable}
        />

        {/* age (years) */}
        <BarsReportAge
          loading={loadingAgeReport}
          report={ageReport}
          getReport={getAllReports}
          showReport={showAgeReport}
          setShowReport={setShowAgeReport}
          showTable={showAgeTable}
          setShowTable={setShowAgeTable}
        />

        <Avaya1Report
          loading={loadingAvaya1}
          report={avaya1}
          getReport={getAvaya1Report}
          showReport={showAvaya1Report}
          setShowReport={setShowAvaya1Report}
          showTable={showAvaya1Table}
          setShowTable={setShowAvaya1Table}
        />

        <Avaya2Report
          title='Avaya2'
          loading={loadingAvaya2}
          report={avaya2}
          getReport={getAvaya2Report}
          showReport={showAvaya2Report}
          setShowReport={setShowAvaya2Report}
        />
      </div>
    </Layout>
  )
}
