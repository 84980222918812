export const COLORS = [
  'rgb(200, 42, 55)',
  'rgb(140, 173, 214)',
  'rgb(209, 206, 206)',
  'rgb(33, 58, 128)',
  'rgb(81, 48, 127)',
  'rgb(123, 124, 97)',
  'rgb(68, 93, 115)',
  'rgb(59, 56, 56)',
  'rgb(19, 121, 148)',
  'rgb(118, 117, 118)',
  'rgb(28, 73, 122)',
  'rgb(49, 85, 152)'
]

export function getRandomColor () {
  var letters = '0123456789ABCDEF'.split('')
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
