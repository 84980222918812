import React from 'react'
import SyncLoader from 'react-spinners/SyncLoader'
import { COLORS } from '../../utilities/colors'

import './styles.scss'

export const BarsReport = ({
  title,
  loading,
  report,
  reportLabel,
  getReport,
  showReport,
  setShowReport,
  showTable,
  setShowTable
}) => {
  return (
    <div className='report'>
      <div className='report__header'>
        <h2>
          {title}
          <div
            className={`arrow${showReport ? ' open' : ''}`}
            onClick={() => {
              if (report.data.length === 0) {
                getReport()
              }
              setShowReport(!showReport)
            }}
          />
        </h2>

        {showReport && (
          <div className='buttons'>
            {
              report.data.length === 0 &&
                <span>No hay registros.</span>
            }

            <button onClick={getReport}>Actualizar</button>

            <button onClick={() => setShowTable(!showTable)}>
              Ver {showTable ? 'gráfica' : 'tabla'}
            </button>
          </div>
        )}
      </div>

      {showReport && (
        <div className='loader'>
          <SyncLoader color='#000' loading={loading} />
        </div>
      )}

      {!showTable && showReport && (
        <div className='report__graph'>
          <div
            className='graph-bars'
            style={{
              display: report.data.length > 0 ? 'flex' : 'none'
            }}
          >
            {report.data.map((row, idx) => {
              return (
                <div
                  key={idx}
                  className='bar'
                  style={{
                    backgroundColor: COLORS[idx % COLORS.length],
                    flexGrow: row.total
                  }}
                >
                  <div className='text'>{row[reportLabel] || 'Vacío'}</div>
                  <div className='number'>{row.total}</div>
                </div>
              )
            })}
          </div>

          {report.data.length > 0 && (
            <div className='total'>
              <div>SUMA TOTAL</div> <strong>{report.total}</strong>
            </div>
          )}
        </div>
      )}

      {showTable && showReport && (
        <div className='report__table'>
          <table
            style={{
              display: !report.data.length ? 'none' : 'table',
              width: '100%'
            }}
          >
            <thead>
              <tr>
                <th>{title}</th>
                <th className='text-right'>Sesiones</th>
              </tr>
            </thead>
            <tbody>
              {report.data.map((row, idx) => {
                return (
                  <tr key={idx}>
                    <td>{row[reportLabel] || 'Vacío'}</td>
                    <td className='text-right'>{row.total}</td>
                  </tr>
                )
              })}
              <tr>
                <td>Total</td>
                <td className='text-right'>{report.total}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
