import React from 'react'
import { Pie } from 'react-chartjs-2'
import { COLORS } from '../../utilities/colors'

/**
 * Retorna una gráfico de torta
 * @param {string} labelField - Nombre del campo que contiene el texto para mostrar
 */
export function ForcesPie ({
  report,
  labelField,
  addReport
}) {
  const data = []
  const backgroundColor = []
  const labels = []

  report.data.forEach((row, idx) => {
    const color = COLORS[idx % COLORS.length]
    const percent = (row.total / report.total * 100).toFixed(2)

    backgroundColor.push(color)
    data.push(row.total)

    labels.push({
      color,
      text: `${percent}% ${row[labelField] || 'Vacío'}`,
      total: row.total,
      id: row.organigram_id,
      percent
    })
  })

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      margin: '0 auto'
    }}
    >
      <div>
        <div>TOTAL: {report.total}</div>

        {labels.map((label, idx) => {
          if (label.total === 0) { return null }

          return (
            <div
              key={idx}
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                margin: '0.5rem 0',
                textTransform: 'uppercase'
              }}
            >
              <div style={{
                color: 'white',
                minWidth: '3em',
                height: '2em',
                backgroundColor: label.color,
                display: 'inline-flex',
                marginRight: '0.5em',
                border: '1px solid white',
                padding: '0 0.25rem',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              >
                {label.total}
              </div>
              <div
                onClick={() => {
                  if (label.id > 0) {
                    addReport({ organigramId: label.id })
                  }
                }}
                style={{
                  cursor: 'pointer'
                }}
              >
                {label.text}
              </div>
            </div>
          )
        })}
      </div>

      <div style={{
        width: '40%'
      }}
      >
        <Pie
          width={40}
          height={40}
          data={{
            datasets: [{
              data,
              backgroundColor
            }],
            labels: labels.map(i => i.text)
          }}
          options={{
            legend: {
              display: false,
              labels: {
                fontColor: 'white',
                fontSize: 18,
                display: false
              },
              position: 'bottom'
            }
          }}
        />
      </div>
    </div>
  )
}
