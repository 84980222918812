import React from 'react'
import SyncLoader from 'react-spinners/SyncLoader'
import { HorizontalBar } from 'react-chartjs-2'

const COLORS = [
  'rgb(200,42,55)',
  'rgb(140,173,214)',
  'rgb(33,58,128)',
  'rgb(19,121,148)'
]

export const BarsReportAge = ({
  loading,
  report,
  getReport,
  showReport,
  setShowReport,
  showTable,
  setShowTable
}) => {
  const datasets = []
  const labels = report.ranks.map(i => i.name)

  report.age_ranges.map((age, idx) => {
    const data = []
    const color = COLORS[idx % COLORS.length]

    report.ranks.forEach(rank => {
      let row = report.data.find(i => i.rank_id === rank.id)
      row = { ...row }
      delete row.rank_id

      data.push(row[age.name])
    })

    datasets.push({
      label: age.description,
      backgroundColor: data.map(() => color),
      data
    })
  })

  return (
    <div className='report'>
      <div className='report__header'>
        <h2>
            Edad
          <div
            className={`arrow${showReport ? ' open' : ''}`}
            onClick={() => {
              if (report.data.length === 0) {
                getReport()
              }
              setShowReport(!showReport)
            }}
          />
        </h2>

        {showReport && (
          <div className='buttons'>
            {
              report.data.length === 0 &&
                <span>No hay registros.</span>
            }

            <button onClick={getReport}>Actualizar</button>

            <button onClick={() => setShowTable(!showTable)}>
                Ver {showTable ? 'gráfica' : 'tabla'}
            </button>
          </div>
        )}

        {showReport && (
          <div className='loader'>
            <SyncLoader color='#000' loading={loading} />
          </div>
        )}
      </div>

      {!showTable && showReport && (
        <div className='report__graph'>
          <HorizontalBar
            width={100}
            height={30}
            responsive
            data={{
              datasets,
              labels
            }}
            options={{
              legend: {
                display: true,
                labels: {
                  fontColor: 'black',
                  fontSize: 16,
                  display: true
                },
                position: 'bottom'
              },
              scales: {
                xAxes: [{
                  stacked: true,
                  ticks: {
                    callback: function (value, index, values) {
                      return value
                    }
                  }
                }],
                yAxes: [{
                  stacked: true,
                  ticks: {
                    callback: function (value, index, values) {
                      return value
                    }
                  }
                }]
              }
            }}
          />
        </div>
      )}

      {
        showTable && showReport && (
          <div className='report__table'>
            <table
              style={{
                display: !report.data.length ? 'none' : 'table',
                width: '100%'
              }}
            >
              <thead>
                <tr>
                  <th>Rango</th>
                  {
                    report.age_ranges.map(age => (
                      <th key={age.name}>{age.description}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  report.ranks.map(rank => (
                    <tr key={rank.id}>
                      <td>{rank.name}</td>
                      {
                        report.age_ranges.map(age => {
                          const row = report.data.find(i => i.rank_id === rank.id)
                          return (
                            <td key={age.name}>{row[age.name]}</td>
                          )
                        })
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        )
      }
    </div>
  )
}
