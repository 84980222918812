import React from 'react'
import SyncLoader from 'react-spinners/SyncLoader'
import { Bar } from 'react-chartjs-2'

const COLORS = ['rgb(140,173,214)', 'rgb(200,42,55)']

export const Avaya2Report = ({
  title,
  loading,
  report = [],
  getReport,
  showReport,
  setShowReport,
}) => {
  const labels = []

  let callsWaitingDataset = {
    backgroundColor: COLORS[0],
    label: 'Llamadas',
    data: [],
  }
  let answeredCallsDataset = {
    type: 'line',
    fill: false,
    pointRadius: 4,
    pointHitRadius: 3,
    pointHoverRadius: 4,
    pointBorderWidth: 8,
    pointHoverBorderWidth: 8,
    borderWidth: 4,
    borderColor: COLORS[1],
    label: 'Atendidas',
    data: [],
  }

  report.forEach(i => {
    labels.push(i.label)
    callsWaitingDataset.data.push(i.calls)
    answeredCallsDataset.data.push(i.answered)
  })

  return (
    <div className="report">
      <div className="report__header">
        <h2>
          {title}
          <div
            className={`arrow${showReport ? ' open' : ''}`}
            onClick={() => {
              if (report.length === 0) {
                getReport()
              }
              setShowReport(!showReport)
            }}
          />
        </h2>

        {showReport && (
          <div className="buttons">
            {report.length === 0 && <span>No hay registros.</span>}

            <button onClick={getReport}>Actualizar</button>
          </div>
        )}
      </div>

      {showReport && (
        <div className="loader">
          <SyncLoader color="#000" loading={loading} />
        </div>
      )}

      {showReport && (
        <div className="report__graph">
          <Bar
            width={100}
            height={30}
            responsive
            data={{
              labels,
              datasets: [answeredCallsDataset, callsWaitingDataset],
            }}
            options={{
              scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
              tooltips: {
                callbacks: {
                  label: function(tooltipItem, data) {
                    let label =
                      data.datasets[tooltipItem.datasetIndex].label + ': '

                    if (tooltipItem.datasetIndex === 0) {
                      label +=
                        (
                          (100 * tooltipItem.yLabel) /
                          report[tooltipItem.index].calls
                        ).toFixed(2) + '%'
                    } else {
                      label += tooltipItem.yLabel
                    }

                    return label
                  },
                },
              },
            }}
          />
        </div>
      )}
    </div>
  )
}
